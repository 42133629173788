import cx from 'classnames';
import dynamic from 'next/dynamic';
import React, { CSSProperties } from 'react';

import { Lozenge } from '../../components/Decorations/Lozenge';
import { One } from '../../components/Decorations/One';
import { Slider } from '../../components/Slider/Slider';
import { SliderColorType } from '../../components/Slider/SliderOptions';
import { ButtonProps } from '../../components/buttons/Button';
import { ButtonGroup } from '../../components/buttons/ButtonGroup';
import {
  ModuleRadiusType,
  ModuleRoundedType,
} from '../../components/module/BackgroundOptions';
import { SpaceType } from '../../components/module/SpacingOptions';
import { AlignType, Text } from '../../components/module/Text';
import { Title } from '../../components/module/Title';
import { Wrapper } from '../../components/module/Wrapper';
import { shuffle } from '../../helpers/utils/array';
import {
  BREAKPOINTS,
  BreakpointType,
  useBreakpoint,
} from '../../hooks/useBreakpoint';
import ErrorBoundary from '../../layout/ModuleBuilder/ErrorBoundary';
import { ColorType } from '../../types';
import {
  gapHorizontalClasses,
  gapVerticalClasses,
  gridCenterClasses,
  gridClasses,
} from './CardGrid.classes';
import {
  BackgroundColorType,
  OrderingType,
  ButtonPositionType,
  ColumnType,
  GapType,
  TitleSizeType,
} from './CardGridOptions';
import { type ComposableCardProps } from './ComposableCard';
import { ComposableCardThemeType } from './ComposableCardOptions';
import { ImageCardProps } from './ImageCard';

const ComposableCard = dynamic<ComposableCardProps>(
  () => import(/* webpackChunkName: "ComposableCard" */ './ComposableCard') as any,
  { suspense: false },
);

const ImageCard = dynamic<ImageCardProps>(
  () => import(/* webpackChunkName: "ImageCard" */ './ImageCard') as any,
  { suspense: false },
);

export type CardGridCardProps = {
  _key?: string;
};

export type CardGridProps = {
  eyebrow?: string;
  title?: string;
  intro?: React.ReactNode;
  items?: ((ComposableCardProps | ImageCardProps) & {
    _key?: string;
    date?: string;
  })[];
  feed?: {
    type?: ComposableCardThemeType;
  };
  buttons?: ButtonProps[];
  theme?: {
    module?: {
      space?: SpaceType;
      background?: BackgroundColorType;
      align?: AlignType;
      rounded?: ModuleRoundedType;
    };
    grid?: {
      columns?: ColumnType;
      gapHorizontal?: GapType;
      gapVertical?: GapType;
      stagger?: boolean;
    };
    title?: {
      size?: TitleSizeType;
    };
    buttons?: {
      position?: ButtonPositionType;
    };
    slider?: {
      mobile?: boolean;
      desktop?: boolean;
      color?: SliderColorType;
    };
    decorations?: {
      showOnes?: boolean;
      showLozenges?: boolean;
      roundedTop?: ModuleRadiusType;
      roundedBottom?: ModuleRadiusType;
    };
    feed?: {
      ordering?: OrderingType;
    };
  };
};

export const CardGrid = ({
  eyebrow,
  title,
  intro,
  items,
  buttons,
  theme,
  feed,
}: CardGridProps) => {
  const { screenWidth, breakpoint } = useBreakpoint();

  let slideColumns = 1;
  if (screenWidth > BREAKPOINTS.xs) slideColumns = 1;
  if (screenWidth > BREAKPOINTS.sm) slideColumns = items?.length === 1 ? 1 : 2;
  if (screenWidth > BREAKPOINTS.md)
    slideColumns = theme?.grid?.columns < 3 ? theme?.grid?.columns : 3;
  if (screenWidth > BREAKPOINTS.lg) slideColumns = theme?.grid?.columns || 3;

  // show half of an extra slide if needed
  if (items?.length > slideColumns) {
    slideColumns = +slideColumns + (screenWidth > BREAKPOINTS.md ? 0.25 : 0.5);
  }

  // slider on mobile by default, adjustable by theme
  let slider = false;
  if (screenWidth < BREAKPOINTS.lg && theme?.slider?.mobile !== false) slider = true;
  if (screenWidth > BREAKPOINTS.lg && theme?.slider?.desktop === true) slider = true;
  if (items?.length === 1) slider = false;

  if (+theme?.grid?.columns === 1) theme.grid.stagger = false;

  // purple background scenario: change overal colouring
  let titleColor: ColorType = 'neutral-base';
  let textColor: ColorType = 'neutral-25';
  let eyebrowColor: ColorType = 'brand-base';
  if (theme?.module?.background === 'brand-dark') {
    titleColor = 'white';
    textColor = 'white';
    eyebrowColor = 'brand-light';
  }

  // work out any custom ordering

  const orderRandom = (items: CardGridProps['items']) => {
    return shuffle(items);
  };

  const orderAlphabetical = (items: CardGridProps['items']) => {
    return items?.sort((a, b) => {
      if (a.type === 'card.composable' && b.type === 'card.composable') {
        return a.title.localeCompare(b.title);
      }
      return 0;
    });
  };

  const orderByDate = (items: CardGridProps['items']) => {
    return items?.sort((a, b) => {
      if (a.type === 'card.composable' && b.type === 'card.composable') {
        return new Date(a.date).valueOf() - new Date(b.date).valueOf();
      }
      return 0;
    });
  };

  let orderedItems = items;
  if (theme?.feed?.ordering === 'alphabetical') {
    orderedItems = orderAlphabetical(items);
  }
  if (theme?.feed?.ordering === 'random') {
    orderedItems = orderRandom(items);
  }
  if (theme?.feed?.ordering === 'date-asc') {
    orderedItems = orderByDate(items);
  }
  if (theme?.feed?.ordering === 'date-desc') {
    orderedItems = orderByDate(items).reverse();
  }

  // read the gap size from the tailwind classes to make grid gaps stay in sync between slider and grid layouts
  const sliderGapSize: number = getSliderGapSize(
    breakpoint,
    theme?.grid?.gapHorizontal || 'xs',
  );

  const hasContentBeforeGrid =
    title ||
    intro ||
    (Boolean(buttons?.length) && theme?.buttons?.position !== 'after');

  return (
    <div className="overflow-hidden relative">
      <Wrapper
        id={title}
        theme={{
          background: theme?.module?.background,
          space: theme?.module?.space,
          rounded: {
            top: theme?.decorations?.roundedTop,
            bottom: theme?.decorations?.roundedBottom,
          },
        }}
        innerClassName="overflow-hidden"
      >
        {theme?.decorations?.showOnes && (
          <div className="absolute inset-0 hidden md:block pointer-events-none ">
            <One
              direction="up"
              color="brand-base"
              className="absolute left-0 -top-9 scale-75 xl:scale-100 -translate-x-[95%] xl:-translate-x-[100%] 2xl:-translate-x-[77%] rotate-[25.4deg]"
            />
            <One
              direction="down"
              color="brand-base"
              className="absolute right-0 top-0 scale-75 xl:scale-100 translate-x-[95%] -translate-y-[60%] xl:translate-x-[100%] 2xl:translate-x-[88%] xl:-translate-y-[52%]"
            />
          </div>
        )}

        {/* lozenges */}
        {theme?.decorations?.showLozenges &&
          (!theme?.grid?.stagger || screenWidth < BREAKPOINTS.lg) && (
            <div className="absolute inset-0 pointer-events-none overflow-hidden">
              <Lozenge
                color={
                  theme?.module?.background === 'neutral-95' ? 'white' : 'neutral-95'
                }
                size="lg"
                rotation={11}
                className={cx('absolute right-0 md:right-[10%] top-0 md:top-[10%]', {
                  ['opacity-10']: theme?.module?.background === 'brand-dark',
                })}
              />
              <Lozenge
                color={
                  theme?.module?.background === 'brand-dark' ? 'white' : 'blue-light'
                }
                size="md"
                rotation={2}
                className="absolute hidden md:block left-[10%] top-[50%]"
              />
              <Lozenge
                color="brand-light"
                size="sm"
                rotation={10}
                className="absolute right-[30%] bottom-0"
              />
            </div>
          )}

        <div
          className={cx('z-20 relative', {
            ['py-8 sm:py-10 lg:py-16 xl:py-30']: theme?.module?.background,
          })}
        >
          <div
            className={cx('max-w-title flex flex-col gap-8', {
              ['md:text-left']: theme?.module?.align === 'left',
              ['md:text-center md:mx-auto']: theme?.module?.align === 'center',
              ['md:text-right md:ml-auto']: theme?.module?.align === 'right',
            })}
          >
            {(Boolean(title?.trim().length) || Boolean(eyebrow?.trim().length)) && (
              <Title
                size={theme?.title?.size || '3xl'}
                eyebrow={eyebrow}
                color={titleColor}
                eyebrowColor={eyebrowColor}
              >
                {title}
              </Title>
            )}
            {intro && (
              <Text
                size="lg"
                color={textColor}
                as="div"
                align={
                  screenWidth < BREAKPOINTS.md
                    ? 'left'
                    : theme?.module?.align === 'center'
                    ? 'center'
                    : theme?.module?.align === 'right'
                    ? 'right'
                    : 'left'
                }
              >
                {intro}
              </Text>
            )}

            {buttons && theme?.buttons?.position !== 'after' && (
              <CardGridButtons buttons={buttons} theme={theme} />
            )}
          </div>

          {Boolean(orderedItems?.length) && (
            <>
              <div
                className={cx('text-left', {
                  ['mt-10 sm:mt-12 md:mt-16 xl:mt-20']: hasContentBeforeGrid,
                })}
              >
                {slider ? (
                  <Slider
                    gap={sliderGapSize}
                    columns={slideColumns}
                    slides={orderedItems?.map((item) => {
                      if (item?.type === 'card.composable')
                        return (
                          <CardWrapper>
                            <ComposableCard
                              {...item}
                              key={item._key}
                              themeName={feed?.type}
                            />
                          </CardWrapper>
                        );
                      if (item?.type === 'card.image')
                        return (
                          <CardWrapper>
                            <ImageCard {...item} key={item._key} />
                          </CardWrapper>
                        );
                    })}
                    controlsColor={theme?.slider?.color}
                  />
                ) : (
                  <div
                    className={cx(
                      'grid',
                      gridClasses[theme?.grid?.columns || 2],
                      gapHorizontalClasses[theme?.grid?.gapHorizontal || 'xs'],
                      gapVerticalClasses[theme?.grid?.gapVertical || 'xs'],
                      {
                        ['pb-20']: theme?.grid?.stagger,
                        [gridCenterClasses[theme?.grid?.columns]]:
                          theme?.module?.align === 'center',
                      },
                    )}
                  >
                    {orderedItems?.map((item, i) => {
                      const staggerStyle: CSSProperties = theme?.grid?.stagger
                        ? {
                            transform: `translateY(${
                              80 + (i % (Math.floor(slideColumns) || 2)) * -80
                            }px)`,
                          }
                        : null;
                      return (
                        <div
                          key={item._key}
                          className="h-full w-full"
                          style={staggerStyle}
                        >
                          <CardWrapper>
                            {item?.type === 'card.composable' && (
                              <ComposableCard {...item} themeName={feed?.type} />
                            )}
                            {item?.type === 'card.image' && (
                              <ImageCard
                                {...item}
                                lozengeVariantIndex={
                                  theme?.decorations?.showLozenges ? i : null
                                }
                              />
                            )}
                          </CardWrapper>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          )}

          {buttons && theme?.buttons?.position === 'after' && (
            <div
              className={cx({
                ['mt-10 md:mt-12 lg:mt-16 xl:mt-20']:
                  !slider || !orderedItems?.length,
              })}
            >
              <CardGridButtons buttons={buttons} theme={theme} />
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

export default React.memo(CardGrid);

type CardGridButtonsProps = {
  buttons?: CardGridProps['buttons'];
  theme?: CardGridProps['theme'];
};

const CardGridButtons = ({ buttons, theme }: CardGridButtonsProps) => {
  const { screenWidth } = useBreakpoint();
  if (!buttons) return null;

  return (
    <div
      className={cx('flex', {
        ['md:justify-start']: theme?.module?.align === 'left',
        ['md:justify-center']: theme?.module?.align === 'center',
        ['md:justify-end']: theme?.module?.align === 'right',
      })}
    >
      <ButtonGroup
        items={buttons}
        stretch={false}
        direction="horizontal"
        align={
          // this is needed to control the wrapping of multiple buttons
          screenWidth < BREAKPOINTS.md
            ? 'left'
            : theme?.module?.align === 'center'
            ? 'center'
            : theme?.module?.align === 'right'
            ? 'right'
            : 'left'
        }
      />
    </div>
  );
};

const CardWrapper = ({ children }: { children: React.ReactNode }) => (
  <React.Suspense>
    <ErrorBoundary>{children}</ErrorBoundary>
  </React.Suspense>
);

const getSliderGapSize = (
  breakpoint: BreakpointType,
  gapHorizontal: GapType,
): number => {
  const sliderGapSizes: Record<BreakpointType, number> = gapHorizontalClasses[
    gapHorizontal
  ]
    .split(' ')
    .reduce((acc, curr) => {
      const breakpoint = (curr.match(/(.*):/)?.[1] || 'xs') as BreakpointType;
      const gap = +curr.match(/\d+$/)[0] * 4;
      return { ...acc, [breakpoint]: gap };
    }, {} as Record<BreakpointType, number>);
  return sliderGapSizes[breakpoint];
};
